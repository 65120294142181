import React from "react"
import { Link } from "gatsby"

// SEO
import { Helmet } from "react-helmet"
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"

// Data
import content from "../pages/static-pages/faqs.yaml"

class Index extends React.Component {
  render() {
    var slugify = require("slugify")
    const breadcrumbs = [
      {
        label: content.title,
        path: "/faqs/",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        {/* Structured Microdata */}
        <Helmet>
          <html itemScope itemType="https://schema.org/FAQPage" lang="en-US" />
        </Helmet>

        <Breadcrumbs items={breadcrumbs} absolute={true} contrast={true} />

        {/* Feature */}
        <div
          className="feature-banner uk-section uk-background-cover uk-light"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-container">
            <div className="uk-width-4-5 uk-width-3-5@s uk-width-1-2@m">
              <h1 className="uk-margin-small-top uk-text-global-family uk-h2 uk-text-bold">
                {content.feature.title}
              </h1>
            </div>
          </div>
        </div>

        <main>
          <div className="uk-section uk-section-default">
            <div className="uk-container">
              <div className="uk-grid" uk-grid="">
                <div className="uk-width-1-3@m">
                  <div uk-sticky="media: @m; offset: 90; bottom: #bottom">
                    <h5>Help by category</h5>
                    <ul
                      className="uk-list uk-list-divider uk-list-border scroll-nav uk-margin-bottom"
                      uk-scrollspy-nav="closest: li; scroll: true; offset: 60;"
                    >
                      {content.faqs.map((faq) => (
                        <li key={faq.title} className="uk-margin-remove">
                          <a
                            className="uk-flex uk-flex-between uk-flex-middle"
                            href={
                              "#" + slugify(faq.title, { lower: true }) + "-faq"
                            }
                          >
                            {faq.title}
                            <span uk-icon="arrow-right"></span>
                          </a>
                        </li>
                      ))}
                    </ul>
                    {/* <div className="uk-background-muted uk-padding-small uk-margin-bottom">
                      <div className="sidebar-cta">
                        <h5 className="uk-margin-small-bottom">
                          Looking to install a new system?
                        </h5>
                        <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                          We're here to help
                        </p>
                        <Link
                          to="/request-an-installer/"
                          className="uk-button uk-button-primary uk-border-pill"
                        >
                          Request an installer
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="uk-width-2-3@m" id="bottom">
                  {content.faqs.map((faq) => (
                    <div
                      key={faq.title}
                      className="faq-section"
                      id={slugify(faq.title, { lower: true }) + "-faq"}
                    >
                      <h4 className="uk-text-muted uk-margin-remove-top">
                        {faq.title}
                      </h4>
                      <h3 className="uk-margin-small-top">{faq.subtitle}</h3>
                      {faq.desc && (
                        <div
                          itemProp="text"
                          dangerouslySetInnerHTML={{
                            __html: faq.desc,
                          }}
                        />
                      )}
                      <ul
                        uk-accordion="multiple: true;"
                        className="uk-accordion uk-accordion-border"
                      >
                        {faq.questions.map((question) => (
                          <li
                            key={question.title}
                            id={slugify(question.title, { lower: true })}
                            itemScope
                            itemProp="mainEntity"
                            itemType="https://schema.org/Question"
                          >
                            <a
                              className="uk-accordion-title"
                              href="#"
                              itemProp="name"
                            >
                              {question.title}
                            </a>
                            <div
                              itemScope
                              itemProp="acceptedAnswer"
                              itemType="https://schema.org/Answer"
                              className="uk-accordion-content"
                            >
                              {question.answer && (
                                <div
                                  itemProp="text"
                                  dangerouslySetInnerHTML={{
                                    __html: question.answer,
                                  }}
                                />
                              )}
                              {question.urls && (
                                <ul>
                                  {question.urls.map((link) => (
                                    <li key={link.title}>
                                      <a
                                        href={link.url}
                                        className="uk-flex uk-flex-middle"
                                        target="_blank"
                                      >
                                        <span
                                          className="uk-flex-none uk-margin-small-right"
                                          uk-icon="file-pdf"
                                        />
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: link.title,
                                          }}
                                        />
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="uk-section uk-section-small">
            <div className="uk-container">
              <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
                <small className="uk-text-meta">{content.description} </small>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default Index
